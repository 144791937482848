import 'core-js';
import 'regenerator-runtime/runtime';
/*
1. user agent
2. isSP
3. anchor
4. phoneLink
5. gnav
6. vue-change-contents
7. moreCropButton
8. toggleNavigation
*/
/**============================================================
*   [user agent]
*   description : ユーザーエージェント判別のクラス付与処理を記述
============================================================*/
var userAgent = window.navigator.userAgent.toLowerCase();
if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
  document.body.classList.add('is-ie');
} else if(userAgent.indexOf('edge') != -1) {
  document.body.classList.add('is-edge');
}

/**============================================================
*   [isSP]
*   description : SPの場合trueを返す処理を記述
============================================================*/
let isSP = (function(){
  var mql = window.matchMedia("(min-width: 767px)");
  if (mql.matches) {
    return false;
  }else{
    return true;
  }
})();

/**============================================================
*   [anchor]
*   description : ページ内リンクの処理を記述
============================================================*/
$('a[href^="#"]').on('click', function() {
  var href      = $(this).attr("href")
     ,target    = $(href == "#" || href == "#top" ? 'html' : href)
     ,speed     = href == "#top" ? 800 : 400
     ,fixedH    = ($(".l-header").css('position') === 'fixed') ? document.querySelector('.l-header').scrollHeight : 0
     ,position  = target.offset().top - fixedH;

  $('body,html').animate({scrollTop:position}, speed, 'swing');
  return false;
});

/**============================================================
*  [phoneLink]
*  description : PCの際に電話のリンクを削除する処理を記述
============================================================*/
if(!isSP && $(".js-phone-link").length > 0){
  document.querySelectorAll(".js-phone-link").forEach(link => {
    const myMain = link;
    const change = document.createElement("span");
    change.className = link.classList;
    change.textContent = link.innerHTML;
    myMain.parentNode.replaceChild(change,myMain);
  })
}

/**============================================================
*   [gnav]
*   description : グローバルナビの処理を記述
============================================================*/
var gnav = new Vue({
  el: '#vue-gnav',
  data: {
    isGnavOpened: false,
    categoryNum: 0,
    isScrolled: false,
    isTopPage: (function(){
      let _href = 'recruit';
      var segments = window.location.pathname.split('/');
      var toDelete = [];
      for (var i = 0; i < segments.length; i++) {
        if (segments[i].length < 1) {
          toDelete.push(i);
        }
      }
      for (var i = 0; i < toDelete.length; i++) {
        segments.splice(i, 1);
      }
      var filename = segments[segments.length - 1];
      return (filename == _href) ? true : false;
    })(),
    isSP: (function(){
      var mql = window.matchMedia("(min-width: 767px)");
      if (mql.matches) {
        return false;
      }else{
        return true;
      }
    })()
  },
  methods: {
    isCategorySelect: function(num) {
      let currentNum = this.categoryNum;
      this.setIsSP();

      if (!this.isSP) {
        //PC
        if(currentNum == num){
          this.categoryNum = 0;
          this.isGnavOpened = false;

        }else{
          this.categoryNum = num;
          this.isGnavOpened = true;

        }
      }else{
        //SP
        if(currentNum == num){
          this.categoryNum = 0;
        }else{
          this.categoryNum = num;
        }
      }

    },
    isGnavClose: function(){
      this.setIsSP();
      this.categoryNum = 0;
      this.isGnavOpened = false;
    },
    setIsSP: function(){
      var mql = window.matchMedia("(min-width: 767px)");
      if (mql.matches) {
        this.isSP = false;
      }else{
        this.isSP = true;
      }
    },
    isToggleGnav: function(){
      this.setIsSP();
      this.categoryNum = 0;
      if(this.isGnavOpened){
        this.isGnavOpened = false;
      }else{
        this.isGnavOpened = true;
      }
    },
    beforeEnter: function(el) {
      this.setIsSP();
      if (this.isSP) {
        el.style.height = '0' + 'px';
      }else{
        el.style.height = '';
      }
    },
    enter: function(el) {
      this.setIsSP();
      if (this.isSP) {
        el.style.height = el.scrollHeight + 15 + 'px';
      }else{
        el.style.height = '';
      }
    },
    beforeLeave: function(el) {
      this.setIsSP();
      if (this.isSP) {
        el.style.height = el.scrollHeight + 15 + 'px';
      }else{
        el.style.height = '';
      }
    },
    leave: function(el) {
      this.setIsSP();
      if (this.isSP) {
        el.style.height = '0' + 'px';
      }else{
        el.style.height = '';
      }
    }
  }
})
/**============================================================
*   [vue-change-contents]
*   description : コンテンツ切り替えの処理を記述
============================================================*/
if(document.getElementById('vue-change-contents')){
  var categoryCange = new Vue({
    el: '#vue-change-contents',
    data: {
      isActive: '1'
    },
    methods: {
      isCategorySelect: function(num) {
        this.isActive = num;
      }
    }
  })
}

/**============================================================
*  [toggleNavigation]
*  description : アコーディオン処理に適用する処理を記述
*              : アコーディオンのボタン設定（※は必須）
*                 1. クラス名※      .js-toggle
*                 2. ターゲット※     data-target="#xxxxx" | "next"（次の.js-toggle-target）
*                 3. 開いた状態      .is-opened（デフォルトで開く場合は、クラスを追加）
*                 4. 他を閉じるか     data-group="任意のグループ名 | false (default)"
*                 5. 開閉時のスクロール位置    data-top="#xxxxx"
============================================================*/
$.script = {
  toggleNavigation: function(){
    var speed         = 300
      , fixedH        = ($(".l-header").css('position') === 'fixed') ? $(".l-header").height() : 0
      , position      = 0;

    function navTargetTop(t){
      var position = $(t).offset().top - fixedH;
      return position;
    }

    function setToggleNav(button) {

      //1. 各ボタン設定
      button.each(function() {

        //2. ターゲット
        var navTarget = $(this).attr('data-target');

        if(navTarget === 'next'){
          navTarget = $(this).next('.js-toggle-target');

        }else if(navTarget === 'nextTaskBody'){
          navTarget = $(this).closest(".task-row").next(".task-body");
        }

        //3. ボタンがactiveであればターゲットを開く。無ければhiddenクラスを追加
        if($(this).hasClass("is-opened")){
          $(navTarget).addClass("is-toggle-active");

        }else{
          $(navTarget).hide().addClass("is-toggle-hidden");
          $(this).addClass("is-toggle-button-hidden");
        }

        $(this).on("click", function(e){
          e.preventDefault();
          e.stopPropagation();

          $(window).trigger("customToggle");

          var group =  $(this).attr('data-group');
          var $groupElem = $('[data-group="'+group+'"]');
          var $topElem = $(this).attr('data-top');
          var $Elem = $(this);

          group = (group != "") ? group : false;
          $topElem = ($topElem) ? $topElem : $Elem;

          if($(navTarget).hasClass("is-toggle-active")){
            // 自身が開いていたら閉じる
            $(navTarget).stop().slideUp(speed,'swing');
            $(navTarget).removeClass("is-toggle-active");
            $(navTarget).addClass("is-toggle-hidden");
            $(this).removeClass("is-opened");
            $(this).addClass("is-toggle-button-hidden");
            $('[data-group="'+group+'"]').removeClass("is-opened");
            $('[data-group="'+group+'"]').addClass("is-toggle-button-hidden");

          }else{
            var _isOpen = false;
            var _isOpenHeight = 0;
            var _isOpenTop = 0;

            //4. 他を閉じるか
            if(group != false){//グループあり

              $($groupElem).each(function(){
                var groupT = $(this).attr('data-target');
                if(groupT === 'next'){
                  groupT = $(this).next('.js-toggle-target');
                }

                if($(groupT).hasClass("is-toggle-active")){
                  _isOpen = true;
                  _isOpenTop = navTargetTop(groupT);
                  _isOpenHeight = $(groupT).outerHeight();
                }
                $(groupT).stop().slideUp(speed,'swing');
                $(groupT).removeClass("is-toggle-active");
                $(groupT).addClass("is-toggle-hidden");
                $('[data-group="'+group+'"]').removeClass("is-opened");
                $('[data-group="'+group+'"]').addClass("is-toggle-button-hidden");
              });

              //閉じていたら開く
              $(navTarget).stop().slideDown(speed,'swing');
              $Elem.removeClass("is-toggle-button-hidden");
              $Elem.addClass("is-opened");
              $(navTarget).removeClass("is-toggle-hidden");
              $(navTarget).addClass("is-toggle-active");

            }else{//グループなし
              $(navTarget).stop().slideDown(speed,'swing');
              $(this).removeClass("is-toggle-button-hidden");
              $(this).addClass("is-opened");
              $(navTarget).removeClass("is-toggle-hidden");
              $(navTarget).addClass("is-toggle-active");
            }

            //5. スクロール位置の指定があれば、そのtopへ移動。無ければターゲットのtopに移動
            if($topElem != false){
                position = navTargetTop($topElem) > _isOpenTop ? (navTargetTop($topElem) - _isOpenHeight) : navTargetTop($topElem);
                $("html, body").stop().animate({scrollTop:position}, speed, "swing");
            }
          }
        });
      });
    }
    setToggleNav($(".js-toggle"));
  }
}
if($(".js-toggle").length > 0) $.script.toggleNavigation();
